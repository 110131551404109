import { getFeatureToggles } from '../../api/togglesApiClient'
import { getWillhabenDatacollectorApiUrl } from '../config/runtimeConfig'

const getDataCollectorBaseUrl = async () => {
    const featureToggles = await getFeatureToggles('jobs')
    if (featureToggles.enableDataCollector) {
        return getWillhabenDatacollectorApiUrl()
    } else {
        return 'https://collector.mpianalytics.com'
    }
}

let collectorBaseUrl: string
export let trackerConfig: { collectorBaseUrl: string }

export const initializeConfig = async () => {
    collectorBaseUrl = await getDataCollectorBaseUrl()
    trackerConfig = {
        collectorBaseUrl: collectorBaseUrl,
    }
}

export const configInitialized = initializeConfig()
